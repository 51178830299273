import React, { useCallback, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const API_HOST = window.ENV_CONFIG.hosts.api;

const Login = ({
  setToken,
  loginError,
  setAdminUsername,
  setAdminPassword,
  adminUsername,
  adminPassword,
  setLoginError,
  setHasLoggedIn,
}) => {
  const login = useCallback(
    async (username, password) => {
      try {
        const login = await fetch(API_HOST + '/user/login', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
          body: JSON.stringify({ username: username, password: password }),
        });
        const json = await login.json();

        if (!login.ok) throw 'error';
        if (!json.user.admin) throw 'You do not have access';

        setLoginError(false);
        setHasLoggedIn(true);
        setToken(json.token);
      } catch (error) {
        setLoginError(true);
      }
    },
    [setHasLoggedIn, setLoginError, setToken]
  );

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        login(adminUsername, adminPassword);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [adminPassword, adminUsername, login]);

  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justify='center'
      style={{ minHeight: '100vh' }}
    >
      <img src='logo.png' alt='Edge logo' className='logo' />
      <Box component='form'>
        <Card className='card login'>
          <Typography variant='h5' component='h3'>
            Admin
          </Typography>

          {loginError && (
            <Alert variant='filled' severity='error'>
              Login failed
            </Alert>
          )}

          <Grid container mb={2}>
            <Grid item xs={12} mb={2}>
              <TextField
                autoFocus
                label='username'
                variant='filled'
                onChange={(e) => {
                  setAdminUsername(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label='password'
                variant='filled'
                type='password'
                onChange={(e) => {
                  setAdminPassword(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Button
            variant='contained'
            onClick={() => login(adminUsername, adminPassword)}
          >
            Log in
          </Button>
        </Card>
      </Box>
    </Grid>
  );
};

export default Login;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const API_HOST = window.ENV_CONFIG.hosts.api;

const AddTopic = ({
  token,
  getTopics,
  getSubCategories,
  subcategories,
  newCategory,
  newSubCategory,
  newSubCategoryParent,
  setNewCategory,
  setNewSubCategory,
}) => {
  const [subCategory, setSubCategory] = useState();
  const [title, setTitle] = useState();
  const [question, setQuestion] = useState();
  const [startDate, setStartDate] = useState();

  const addTopic = () => {
    if (!subCategory || !title || !question || !startDate) {
      alert('Please make sure all fields are filled in.');
    }

    if (subCategory && title && question && startDate) {
      if (window.confirm('Are you sure you wish to add this topic?')) {
        (async () => {
          try {
            const rawResponse = await fetch(API_HOST + '/topic', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: token,
              },
              body: JSON.stringify({
                sub_category: subCategory,
                title: title,
                question: question,
                start_date: startDate,
              }),
            });

            const rawJson = await rawResponse.json();

            if (!rawResponse.ok) throw rawJson;

            getTopics();
          } catch (e) {
            alert(`${e.message}. Check console for further details.`);
            window.console.error(e);
          }
        })();
      }
    }
  };

  const addNewCategory = async () => {
    await fetch(API_HOST + 'topic/category/', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ name: newCategory }),
    });

    getSubCategories();
  };

  const addNewSubcategory = async () => {
    await fetch(API_HOST + 'topic/subcategory/', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        name: newSubCategory,
        parent_category: newSubCategoryParent,
      }),
    });

    getSubCategories();
  };

  return (
    <div style={{ paddingBottom: 50 }}>
      <h2>Add new topic</h2>
      <Grid container>
        <Grid item xs={12}>
          <Card className='card'>
            <FormControl
              variant='filled'
              margin='normal'
              style={{ width: 250 }}
            >
              <InputLabel htmlFor={'add-subcategory'}>Sub-category</InputLabel>
              <Select
                native
                defaultValue={subCategory}
                input={<Input name='add-subcategory' id='add-subcategory' />}
                onChange={(e) => setSubCategory(e.target.value)}
              >
                <option></option>
                {subcategories.map((subCat, index) => {
                  return (
                    <option value={subCat} key={index}>
                      {subCat}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              label='Topic title'
              variant='filled'
              margin='normal'
              placeholder='Premier League Football'
              type='text'
              onChange={(e) => setTitle(e.target.value)}
            />

            <TextField
              label='Question'
              variant='filled'
              margin='normal'
              placeholder='Will Arsenal beat Watford?'
              type='text'
              onChange={(e) => setQuestion(e.target.value)}
            />

            <TextField
              label='Date'
              variant='filled'
              margin='normal'
              type='datetime-local'
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setStartDate(e.target.value)}
            />

            <Button variant='contained' onClick={addTopic}>
              Add topic
            </Button>
          </Card>
        </Grid>
      </Grid>

      <h2>Add new category</h2>
      <Grid container>
        <Grid item xs={12}>
          <Card className='card'>
            <TextField
              label='Name'
              variant='filled'
              onChange={(e) => setNewCategory(e.target.value)}
            />

            <Button
              variant='contained'
              style={{ marginTop: 10 }}
              onClick={async (e) => await addNewCategory()}
            >
              Submit
            </Button>
          </Card>
        </Grid>
      </Grid>

      <h2>Add new sub-category</h2>
      <Grid container>
        <Grid item xs={12}>
          <Card className='card'>
            <TextField
              label='Name'
              variant='filled'
              onChange={(e) => setNewSubCategory(e.target.value)}
            />

            <Button
              variant='contained'
              style={{ marginTop: 10 }}
              onClick={async (e) => await addNewSubcategory()}
            >
              Submit
            </Button>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddTopic;

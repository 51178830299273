import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const API_HOST = window.ENV_CONFIG.hosts.api;

const Topics = ({
  token,
  getTopics,
  topics,
  setTopics,
  subcategories,
  activeTopicTab,
}) => {
  const [rejectionReason, setRejectionReason] = useState();

  const updateTopic = (topic, newData, index) => {
    const sure = window.confirm('Are you sure you wish to update this topic?');

    if (sure) {
      (async () => {
        try {
          const rawResponse = await fetch(`${API_HOST}/topic/${topic._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
            body: JSON.stringify(newData),
          });

          const rawJson = await rawResponse.json();

          if (!rawResponse.ok) throw rawJson;

          let newTopics = topics;
          newTopics[index] = { ...topic, ...newData };
          setTopics(newTopics);
        } catch (e) {
          alert(`${e.message}. Check console for further details.`);
          window.console.error(e);
        }
      })();
    }
  };

  const updateTopicStatus = async (e, topic, i) => {
    const value = e.target.value;

    if (value === 'rejected') {
      if (
        !rejectionReason ||
        !rejectionReason.length ||
        rejectionReason === ''
      ) {
        alert('Please select a reason first.');
        return;
      }
    }

    if (
      window.confirm(
        `Are you sure you want to update the status of "${topic.question}" to "${value}"?`
      )
    ) {
      try {
        await fetch(`${API_HOST}/topic/${topic._id}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({
            status: value,
          }),
        });

        let newTopics = topics;
        newTopics[i].status = value;
        setTopics(newTopics);
        getTopics();
      } catch (e) {
        alert('An error occurred. Check console for details.');
        window.console.error(e);
      }
    }
  };

  const rejectTopic = async (rejection_reason, topic, i) => {
    try {
      if (
        !rejection_reason ||
        !rejection_reason.length ||
        rejection_reason === ''
      ) {
        alert('Please select a reason first.');
        return;
      }

      await fetch(API_HOST + '/topic/' + topic._id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          rejection_reason: rejection_reason,
        }),
      });

      let newTopics = topics;
      newTopics[i].status = 'rejected';
      newTopics[i].rejection_reason = rejection_reason;
      setTopics(newTopics);
    } catch (e) {
      alert('An error occurred. Check console for details.');
      window.console.error(e);
    }
  };

  return (
    <div style={{ paddingBottom: 50 }}>
      {topics.map &&
        topics.map((topic, i) => {
          let { question, rejection_reason, start_date, status, title, matchedBetCount } = topic;

          let filterStatus = topic.status === 'open';

          if (activeTopicTab === 'In progress')
            filterStatus = topic.status === 'in_progress';

          if (activeTopicTab === 'Awaiting review')
            filterStatus = topic.status === 'awaiting_review';

          if (filterStatus) {
            return (
              <Card key={topic._id} className='card'>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={6}>
                    <FormControl variant='filled' style={{ width: 250 }}>
                      <InputLabel htmlFor={'update-subcategory'}>
                        Sub-category
                      </InputLabel>
                      <Select
                        native
                        defaultValue={topic.sub_category}
                        input={
                          <Input
                            name='update-subcategory'
                            id='update-subcategory'
                          />
                        }
                        onChange={(e) =>
                          updateTopic(
                            topic,
                            { sub_category: e.target.value },
                            i
                          )
                        }
                      >
                        {subcategories.map((subCat, index) => {
                          return (
                            <option value={subCat} key={index}>
                              {subCat}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display='flex' justifyContent='flex-end'>
                      <Typography
                        paragraph
                        fontStyle='italic'
                        color='#b6b6bf'
                        fontSize='13px'
                        pt='3px'
                        mr='6px'
                      >
                        suggested by
                      </Typography>
                      <Typography paragraph>
                        {topic.created_by.username}
                      </Typography>
                    </Box>
                    <Box display='flex' justifyContent='flex-end'>
                    {matchedBetCount} matched bets
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mb={2}>
                  <Grid item xs={6}>
                    <TextField
                      label='Title'
                      variant='filled'
                      defaultValue={title}
                      onBlur={(e) => {
                        updateTopic(topic, { title: e.target.value }, i);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='Question'
                      variant='filled'
                      defaultValue={question}
                      onBlur={(e) => {
                        updateTopic(topic, { question: e.target.value }, i);
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} mb={2}>
                  <Grid item xs={6}>
                    <TextField
                      label='Date'
                      variant='filled'
                      defaultValue={start_date}
                      InputLabelProps={{ shrink: true }}
                      onBlur={(e) =>
                        updateTopic(topic, { start_date: e.target.value }, i)
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={activeTopicTab === 'Awaiting review' ? 6 : 12}
                      >
                        <FormControl variant='filled' sx={{ width: '100%' }}>
                          <InputLabel htmlFor={'topic-status-' + topic._id}>
                            Status
                          </InputLabel>
                          <Select
                            native
                            label='Status'
                            id={'topic-status-' + topic._id}
                            defaultValue={status}
                            onChange={async (e) =>
                              await updateTopicStatus(e, topic, i)
                            }
                          >
                            <option></option>
                            <option value='hidden'>hidden</option>
                            <option value='open'>open</option>
                            <option value='in_progress'>in_progress</option>
                            <option value='void'>void</option>
                            <option value='winner_A'>
                              Winner - {topic.option_A}
                            </option>
                            <option value='winner_B'>
                              Winner - {topic.option_B}
                            </option>
                            <option value='payment_pending'>
                              payment_pending
                            </option>
                            <option value='awaiting_review'>
                              awaiting_review
                            </option>
                            <option value='rejected'>rejected</option>
                            <option value='spam'>spam</option>
                          </Select>
                        </FormControl>
                      </Grid>

                      {activeTopicTab === 'Awaiting review' && (
                        <Grid item xs={6}>
                          <FormControl
                            variant='filled'
                            sx={{ width: '100%', mb: 1 }}
                          >
                            <InputLabel
                              htmlFor={'topic-rejection-reason-' + topic._id}
                            >
                              Rejection reason
                            </InputLabel>
                            <Select
                              native
                              label='Rejection reason'
                              id={'topic-rejection-reason-' + topic._id}
                              defaultValue={rejectionReason}
                              onChange={async (e) => {
                                setRejectionReason(e.target.value);
                              }}
                            >
                              <option></option>
                              <option value='duplicate'>
                                The topic already exists
                              </option>
                              <option value='imbalanced_odds'>
                                One option is too unlikely
                              </option>
                              <option value='too_soon'>
                                Not enough time to place bets
                              </option>
                              <option value='too_late'>
                                Result too far in the future
                              </option>
                              <option value='unpopular'>
                                Topic not popular enough
                              </option>
                            </Select>
                          </FormControl>

                          <div className='field'>
                            <Button
                              variant='contained'
                              onClick={async () =>
                                await rejectTopic('duplicate', topic, i)
                              }
                            >
                              REJECT - Already exists
                            </Button>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            );
          }
        })}
    </div>
  );
};

export default Topics;

import React, { useCallback, useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNav from './pages/navigation/BottomNavigation';
import TopNavigation from './pages/navigation/TopNavigation';
import Login from './pages/login/Login';
import Topics from './pages/topics/Topics';
import Avatars from './pages/avatars/Avatars';
import AddTopic from './pages/addTopic/AddTopic';
import './App.css';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#100f2b',
    },
    primary: {
      main: '#7e3ef5',
      light: '#00ffff',
      dark: '#6f27f4',
    },
  },
});

const API_HOST = window.ENV_CONFIG.hosts.api;

const App = () => {
  const [token, setToken] = useState();
  const [topics, setTopics] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [activePage, setActivePage] = useState('Topics');
  const [activeTopicTab, setActiveTopicTab] = useState('In progress');

  const [loginError, setLoginError] = useState();
  const [adminUsername, setAdminUsername] = useState();
  const [adminPassword, setAdminPassword] = useState();
  const [newCategory, setNewCategory] = useState();
  const [newSubCategory, setNewSubCategory] = useState();
  const [newSubCategoryParent] = useState();

  const [avatarList, setAvatarList] = useState();
  const [avatarSets, setAvatarSets] = useState();

  useEffect(() => {
    if (typeof token !== 'undefined') {
      getTopics();
    }
  }, [token]);

  const getTopics = () => {
    (async () => {
      if (!token) {
        return;
      }

      const newToken = token;
      const rawResponse = await fetch(API_HOST + '/topic/list?status=open,in_progress,awaiting_review&limit=500', {
        headers: { Authorization: newToken },
      });
      const content = await rawResponse.json();

      setTopics(content);
    })();
  };

  const getSubCategories = useCallback(() => {
    (async () => {
      const newToken = token;
      const subcatResponse = await fetch(API_HOST + '/topic/subcategory', {
        headers: { Authorization: newToken },
      });
      const subcatContent = await subcatResponse.json();

      setSubcategories(subcatContent);
    })();
  }, [token]);

  useEffect(() => {
    if (typeof token !== 'undefined') {
      getSubCategories();
    }
  }, [getSubCategories, token]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div>
        {!hasLoggedIn && (
          <Login
            setToken={setToken}
            loginError={loginError}
            setAdminUsername={setAdminUsername}
            setAdminPassword={setAdminPassword}
            adminUsername={adminUsername}
            adminPassword={adminPassword}
            setLoginError={setLoginError}
            setHasLoggedIn={setHasLoggedIn}
          />
        )}

        {hasLoggedIn && activePage === 'Topics' && (
          <>
            <TopNavigation
              activeTopicTab={activeTopicTab}
              setActiveTopicTab={setActiveTopicTab}
              topics={topics}
            />

            <Topics
              token={token}
              getTopics={getTopics}
              topics={topics}
              setTopics={setTopics}
              subcategories={subcategories}
              activeTopicTab={activeTopicTab}
            />
          </>
        )}

        {hasLoggedIn && activePage === 'Add Topic' && (
          <AddTopic
            token={token}
            getTopics={getTopics}
            getSubCategories={getSubCategories}
            subcategories={subcategories}
            newCategory={newCategory}
            newSubCategory={newSubCategory}
            newSubCategoryParent={newSubCategoryParent}
            setNewCategory={setNewCategory}
            setNewSubCategory={setNewSubCategory}
          />
        )}

        {hasLoggedIn && activePage === 'Avatars' && (
          <Avatars
            token={token}
            onAvatars={({ avatarList, avatarSets }) => {
              setAvatarList(avatarList);
              setAvatarSets(avatarSets);
            }}
            avatarSets={avatarSets}
            setAvatarSets={setAvatarSets}
            avatarList={avatarList}
          />
        )}

        {hasLoggedIn && (
          <BottomNav activePage={activePage} setActivePage={setActivePage} />
        )}
      </div>
    </ThemeProvider>
  );
};

export default App;

import React, { useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Download from '@mui/icons-material/Download';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Card from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Checkbox, FormControlLabel } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

const API_HOST = window.ENV_CONFIG.hosts.api;

const Avatars = ({ token, onAvatars, avatarSets, setAvatarSets }) => {
  const [newAvatar, setNewAvatar] = useState({
    description: '',
    image_path: 'https://edgeapp.live/images/avatars/default/default-01.png',
    is_default: false,
    hidden: true,
    name: 'New Avatar',
    numSold: 0,
    ecoinPrice: 1000000,
    soldOut: false,
    supply: 0,
  });

  const getAvatarSets = async () => {
    try {
      const rawResponse = await fetch(API_HOST + '/avatar/set/list?all=true', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      const sets = await rawResponse.json();
      if (!rawResponse.ok) throw sets;

      onAvatars({ avatarSets: sets });
    } catch (e) {
      alert('An error occurred. Check console for details.');
      window.console.error(e);
    }
  };

  const updateSet = (set, changes, setIndex, avatarIndex) => {
    (async () => {
      try {
        const avatarSetsResponse = await fetch(
          `${API_HOST}/avatar/set/${set._id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
            body: JSON.stringify(changes),
          }
        );

        const avatarSetsJson = await avatarSetsResponse.json();

        if (!avatarSetsResponse.ok) throw avatarSetsJson;

        let newSets = avatarSets;

        if (avatarIndex !== undefined) {
          newSets[setIndex].avatars = avatarSetsJson.avatars;
        } else {
          newSets[setIndex] = { ...set, ...changes };
        }

        setAvatarSets(newSets);
      } catch (e) {
        alert(`${e.message}. Check console for further details.`);
        window.console.error(e);
      }
    })();
  };

  const deleteSet = (set, index) => {
    const sure = window.confirm(`Are you sure you wish to delete ${set.name}?`);

    if (sure) {
      (async () => {
        try {
          const deleteResponse = await fetch(
            `${API_HOST}/avatar/set/${set._id}`,
            {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                Authorization: token,
              },
            }
          );

          const deleteJson = await deleteResponse.json();

          if (!deleteResponse.ok) throw deleteJson;

          let newSets = avatarSets;
          newSets.splice(index, 1);
          setAvatarSets(newSets);
          getAvatarSets();
        } catch (e) {
          alert(`${e.message}. Check console for further details.`);
          window.console.error(e);
        }
      })();
    }
  };

  const createSet = (set) => {
    (async () => {
      try {
        const rawResponse = await fetch(`${API_HOST}/avatar/set`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(set),
        });

        const rawJson = await rawResponse.json();

        if (!rawResponse.ok) throw rawJson;

        let newSets = avatarSets;
        newSets.push(rawJson);
        setAvatarSets(newSets);
        getAvatarSets();
      } catch (e) {
        alert(`${e.message}. Check console for further details.`);
        window.console.error(e);
      }
    })();
  };

  const handleAvatarChange = (set, setIndex, avatarIndex, key, value) => {
    const updatedAvatars = set.avatars.map((avatar, index) =>
      index === avatarIndex ? { ...avatar, [key]: value } : avatar
    );
    updateSet(set, { avatars: updatedAvatars }, setIndex, avatarIndex);
  };

  const handleAddAvatar = (setIndex) => {
    const updatedSets = avatarSets.map((set, index) =>
      index === setIndex
        ? { ...set, avatars: [...set.avatars, newAvatar] }
        : set
    );
    setAvatarSets(updatedSets);
    setNewAvatar({
      description: '',
      image_path: 'https://edgeapp.live/images/avatars/default/default-01.png',
      is_default: false,
      hidden: true,
      name: 'New Avatar',
      numSold: 0,
      ecoinPrice: 1000000,
      soldOut: false,
      supply: 0,
    });
  };

  const handleAddAvatarSet = () => {
    createSet({
      name: 'New Avatar Set',
      hidden: true,
      avatars: [],
      launchDate: Date(),
      created_by: 'sam',
    });
  };

  return (
    <div style={{ paddingBottom: 50, paddingTop: '48px' }}>
      <Stack
        direction='row'
        spacing={4}
        style={{
          top: 0,
          left: 0,
          margin: 0,
          backgroundColor: '#08071c',
          justifyContent: 'center',
          height: '48px',
          padding: '5px',
          position: 'fixed',
          width: '100%',
          zIndex: 10,
        }}
      >
        <Button
          variant='contained'
          onClick={getAvatarSets}
          startIcon={<Download />}
        >
          Get avatars
        </Button>

        <Button
          variant='contained'
          onClick={handleAddAvatarSet}
          startIcon={<AddCircleOutline />}
        >
          New Avatar Set
        </Button>
      </Stack>

      {avatarSets &&
        avatarSets.map((set, setIndex) => {
          let { name, created_by, avatars, launchDate, hidden, featured, _id } =
            set;
          return (
            <Accordion key={_id} style={{ backgroundColor: '#08071c' }}>
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                <Typography>{set.name}</Typography>
              </AccordionSummary>

              <Card key={_id} className='card'>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={6}>
                    <TextField
                      label='Name'
                      variant='filled'
                      defaultValue={name}
                      onBlur={(e) => {
                        updateSet(set, { name: e.target.value }, setIndex);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='Created By'
                      variant='filled'
                      defaultValue={created_by.username}
                      onBlur={(e) => {
                        updateSet(
                          set,
                          { created_by: e.target.value },
                          setIndex
                        );
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} mb={2}>
                  <Grid item xs={6}>
                    <TextField
                      label='Launch Date'
                      variant='filled'
                      defaultValue={launchDate}
                      InputLabelProps={{ shrink: true }}
                      onBlur={(e) =>
                        updateSet(set, { launchDate: e.target.value }, setIndex)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={hidden}
                          onChange={(e) =>
                            updateSet(
                              set,
                              { hidden: e.target.checked },
                              setIndex
                            )
                          }
                        />
                      }
                      label='Hidden'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={featured}
                          onChange={(e) =>
                            updateSet(
                              set,
                              { featured: e.target.checked },
                              setIndex
                            )
                          }
                        />
                      }
                      label='Featured'
                    />
                    <Button
                      variant='contained'
                      onClick={(e) => {
                        deleteSet(set, setIndex);
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>

                {avatars &&
                  avatars.map((avatar, avatarIndex) => (
                    <Card key={avatar._id} className='avatar-card'>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={6}>
                          <TextField
                            label='Name'
                            variant='filled'
                            defaultValue={avatar.name}
                            onBlur={(e) =>
                              handleAvatarChange(
                                set,
                                setIndex,
                                avatarIndex,
                                'name',
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label='Image Path'
                            variant='filled'
                            defaultValue={avatar.image_path}
                            onBlur={(e) =>
                              handleAvatarChange(
                                set,
                                setIndex,
                                avatarIndex,
                                'image_path',
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={6}>
                          <TextField
                            label='Description'
                            variant='filled'
                            defaultValue={avatar.description}
                            onBlur={(e) =>
                              handleAvatarChange(
                                set,
                                setIndex,
                                avatarIndex,
                                'description',
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label='Ecoin Price'
                            variant='filled'
                            defaultValue={avatar.ecoinPrice}
                            onBlur={(e) =>
                              handleAvatarChange(
                                set,
                                setIndex,
                                avatarIndex,
                                'ecoinPrice',
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={6}>
                          <TextField
                            label='Supply'
                            variant='filled'
                            defaultValue={avatar.supply}
                            onBlur={(e) =>
                              handleAvatarChange(
                                set,
                                setIndex,
                                avatarIndex,
                                'supply',
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label='Num Sold'
                            variant='filled'
                            defaultValue={avatar.numSold}
                            onBlur={(e) =>
                              handleAvatarChange(
                                set,
                                setIndex,
                                avatarIndex,
                                'numSold',
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={avatar.is_default}
                                onChange={(e) =>
                                  handleAvatarChange(
                                    set,
                                    setIndex,
                                    avatarIndex,
                                    'is_default',
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label='Is Default'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={avatar.hidden}
                                onChange={(e) =>
                                  handleAvatarChange(
                                    set,
                                    setIndex,
                                    avatarIndex,
                                    'hidden',
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label='Hidden'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={avatar.soldOut}
                                onChange={(e) =>
                                  handleAvatarChange(
                                    set,
                                    setIndex,
                                    avatarIndex,
                                    'soldOut',
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label='Sold Out'
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  ))}

                <Button
                  variant='contained'
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => handleAddAvatar(setIndex)}
                >
                  Add Avatar
                </Button>
              </Card>
            </Accordion>
          );
        })}
    </div>
  );
};

export default Avatars;

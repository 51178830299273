import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import _ from 'lodash';

const TopNavigation = ({ activeTopicTab, setActiveTopicTab, topics }) => {
  return (
    <div style={{ paddingBottom: 50 }}>
      <AppBar color='primary' position='fixed'>
        <Tabs
          centered
          value={activeTopicTab}
          onChange={(e, newValue) => setActiveTopicTab(newValue)}
        >
          <Tab
            variant='fullWidth'
            wrapped
            value='In progress'
            label={`In progress (${
              _.filter(topics, { status: 'in_progress' }).length
            })`}
          />

          <Tab
            variant='fullWidth'
            value='Open'
            label={`Open (${
              _.filter(
                topics,
                (o) => o.status === 'open'
              ).length
            })`}
          />

          <Tab
            wrapped
            variant='fullWidth'
            value='Awaiting review'
            label={`Awaiting review (${
              _.filter(topics, (o) => o.status === 'awaiting_review').length
            })`}
          />
        </Tabs>
      </AppBar>
    </div>
  );
};

export default TopNavigation;

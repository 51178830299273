import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AvatarIcon from '@mui/icons-material/PeopleOutlineRounded';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const BottomNav = ({ activePage, setActivePage }) => {
  return (
    <BottomNavigation
      value={activePage}
      onChange={(event, newValue) => {
        setActivePage(newValue);
      }}
      showLabels
      style={{ position: 'fixed', width: '100%', bottom: 0, left: 0 }}
    >
      <BottomNavigationAction
        label='Topics'
        value='Topics'
        icon={<LibraryBooksIcon />}
      />
      <BottomNavigationAction
        label='Add Topic'
        value='Add Topic'
        icon={<AddCircleOutlineIcon />}
      />
      <BottomNavigationAction
        label='Avatars'
        value='Avatars'
        icon={<AvatarIcon />}
      />
    </BottomNavigation>
  );
};

export default BottomNav;
